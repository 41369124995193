import treeHelperMixin from '@/components/tree/mixins/treeHelperMixin'
export default {
  mixins: [treeHelperMixin],
  metaInfo() {
    return {
      title: this.pageTitle,
    }
  },
  data: (vm) => ({
    treeKey: `tree-${vm._uid}`,
    hasInit: false,
    pageTitle: '',
  }),
  async created() {
    await this._initTree()
  },
  methods: {
    async _initTree() {
      this._initVuexModule()
      this.$store.dispatch(`tree/${this.treeKey}/setApi`, {
        createApi: this.createApi,
        updateApi: this.updateApi,
        getTreeApi: this.getTreeApi,
        moveApi: this.moveApi,
        deleteApi: this.deleteApi,
      })

      this.$store.dispatch(`tree/${this.treeKey}/setActions`, {
        refreshTree: () => this._initTreeData(false),
        createChild: this._createChild,
      })

      this.setupPageTitle()
      this.$store.dispatch(`tree/${this.treeKey}/set`, {
        key: 'pageTitle', value: this.pageTitle,
      })
      await this._setupMeta()
      await this._initTreeData()
    },
    async _setupMeta() {
      if(typeof this.beforeLoadTree != 'function') {
        this.$store.dispatch(`tree/${this.treeKey}/setMeta`, null)
        return
      }
      try {
        await this.beforeLoadTree()
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch(`tree/${this.treeKey}/setMeta`, window.eagleLodash.cloneDeep(this.meta))
      }
    },
    setupPageTitle() {
      if(!this.treeConfig) return this.pageTitle = ''
      if(!this.treeConfig.pageTitle) return this.pageTitle = ''
      this.pageTitle = this.$t(this.treeConfig.pageTitle) || ''
    },
    async _initTreeData(loading = true) {
      if(loading) {
        this.$store.dispatch('loading/active')
        this.$store.dispatch('loading/progress')
      }

      try {
        const result = await this.getTreeApi()
        this.$store.dispatch(`tree/${this.treeKey}/setData`, result)
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('data_load.failure.content'),
          this.$t('data_load.failure'),
        )
        this.$store.dispatch(`tree/${this.treeKey}/set`, { key: 'loadFailure', value: true })
      } finally {
        this.$store.dispatch('loading/close')
        this.$store.dispatch('loading/closeProgress')
      }
    },
  },
  components: {
    'eagle-tree': () => import('@/components/tree/tree.vue'),
  },
}
