import {ActionContext} from 'vuex/types'
import {EagleTreeConfigInterface} from 'components/tree/types/tree'
declare type nodeMoveAction = 'moveBefore'|'moveAfter'|'moveInside'|null
declare type moveConfig = {
  node: string|null,
  parent: string|null,
  action: nodeMoveAction,
  hasWritePermission: boolean,
}

declare interface TreeInstanceStateInterface {
  config: AnyObject,
  hasWritePermission: boolean
  api: AnyObject | null,
  depthOfType: number,
  rootId: string|null,
  data: AnyObject|null,
  meta: null,
  move: {
    node: string|null,
    parent: string|null
    action: nodeMoveAction,
  },
  actions: AnyObject|null,
  loadFailure: boolean,
  createActionsBar: boolean,
  pageTitle: string|null,
}

declare interface TreeInstanceInterface {
  namespaced: boolean
  state: () => TreeInstanceStateInterface
  mutations: AnyObject
  actions: AnyObject
  getters: AnyObject
}

declare interface TreeInstanceOptionInterface {
  config: EagleTreeConfigInterface
  bindRoute: boolean
  hasWritePermission: boolean
}

export default (options: TreeInstanceOptionInterface) : TreeInstanceInterface => {
  const {
    config,
    hasWritePermission
  } = options

  return {
    namespaced: true,
    state: () => ({
      config,
      hasWritePermission,
      rootId: null,
      depthOfType: -1,
      meta: null,
      api: null,
      data: null,
      actions: {},
      loadFailure: false,
      move: {
        node: null,
        parent: null,
        action: null,
      },
      createActionsBar: true,
      pageTitle: null,
    }),
    mutations: {
      set: (state: TreeInstanceStateInterface, data: {[key:string]: any}) => {
        // @ts-ignore
        state[data.key] = data.value
        window.eagleLodash.set(state, data.key, data.value)
      },
      setData: (state: TreeInstanceStateInterface, data: any) => {
        state.rootId = data.id
        state.data = window.eagleLodash.cloneDeep(data.children)
      },
      setActions: (state: TreeInstanceStateInterface, actions: any) => {
        state.actions = actions
      },
      setApi: (state: TreeInstanceStateInterface, api: { [key:string]: any }) => {
        state.api = api
      },
      setMeta(state: TreeInstanceStateInterface, meta: { [key:string]: any }|null) {
        state.meta = window.eagleLodash.cloneDeep(meta)
      },
      move(state: TreeInstanceStateInterface, config: moveConfig) {
        state.move.node = config.node
        state.move.parent = config.parent
        state.move.action = config.action
      },
      clearMove(state: TreeInstanceStateInterface) {
        state.move.node = null
        state.move.action = null
      },
    },
    actions: {
      set: (context: ActionContext<any, any>, data: {[key:string]: any}) => {
        context.commit('set', data)
      },
      setData: (context: ActionContext<any, any>, result: any) => {
        context.commit('setData', result)
      },
      setActions: (context: ActionContext<any, any>, actions: any) => {
        context.commit('setActions', actions)
      },
      setApi: (context: ActionContext<any, any>, api: { [key:string]: any }) => {
        context.commit('setApi', api)
      },
      setMeta: (context: ActionContext<any, any>, meta: { [key:string]: any }|null) => {
        context.commit('setMeta', meta)
      },
      move: (context: ActionContext<any, any>, config: moveConfig) => {
        context.commit('move', config)
      },
      clearMove: (context: ActionContext<any, any>) => {
        context.commit('clearMove')
      },
    },
    getters: {
      config: (state: TreeInstanceStateInterface) => state.config,
      data: (state: TreeInstanceStateInterface) => state.data,
      api: (state: TreeInstanceStateInterface) => state.api,
      actions: (state: TreeInstanceStateInterface) => state.actions,
      meta: (state: TreeInstanceStateInterface) => state.meta,
      loadFailure: (state: TreeInstanceStateInterface) => state.loadFailure,
      rootId: (state: TreeInstanceStateInterface) => state.rootId,
      createActionsBar: (state: TreeInstanceStateInterface) => state.createActionsBar,
      move: (state: TreeInstanceStateInterface) => state.move,
      hasMoveNode: (state: TreeInstanceStateInterface) => !!state.move.node,
      moveNode: (state: TreeInstanceStateInterface) => state.move.node,
      pageTitle: (state: TreeInstanceStateInterface) => state.pageTitle,
      depthOfType: (state: TreeInstanceStateInterface) => state.depthOfType,
      treeEmpty: (state: TreeInstanceStateInterface, getters: AnyObject) => {
        if(!Array.isArray(getters.data)) return false
        return getters.data.length === 0
      },
      hasWritePermission: (state: TreeInstanceStateInterface, getters: AnyObject) => state.hasWritePermission,
    },
  }
}
