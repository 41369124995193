import treeInstance from '@/store/modules/treeInstance'

export default {
  methods: {
    _hasModule() {
      return this.$store.getters[`tree/${this.treeKey}/config`] != undefined
    },
    _initVuexModule() {
      this.hasInit = false
      if(this._hasModule()) {
        this._destroyVuexModule()
      }
      if(this._hasModule() === false) {
        const options = {
          config: this.getTreeConfig().get(this, this.treeKey),
        }
        options.hasWritePermission = this.checkHasWritePermission(options.config)
        this.$store.registerModule(['tree', this.treeKey], treeInstance(options))
      }
      this.hasInit = true
    },
    _destroyVuexModule() {
      if(this._hasModule() === false) return
      this.$store.unregisterModule(['tree', this.treeKey])
    },
    _createChild(parentId, options = {}) {
      this.$apopup.prompt({
        title: options.title || this.$t('action.create'),
        promptPlaceholder: this.$t('action.tree_node.create.help'),
        promptMaxLength: 50,
        applyCallback: name => {
          this._createNodeRequest(parentId, name)
        },
        disabledApply: data => window.eagleLodash.isEmpty(window.eagleLodash.trim(data)),
      })
    },
    async _createNodeRequest(parent, name) {
      let result = null
      try {
        result = await this.treeApi.createApi(parent, { name })
        this.$snotify.success(
          name,
          this.$t('save.successfully'),
        )
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('save.failure'),
        )
      } finally {
        this._clearMove()
        await this.treeActions.refreshTree()
        this._scrollToNode(result)
      }
    },
    _clearMove() {
      this.$store.dispatch(`tree/${this.treeKey}/clearMove`)
    },
    async _scrollToNode(node) {
      if(!node) return
      await this.$nextTick()
      const $node = $(`*[eagle-tree="node"][node-id=${node.id}]`)
      if($node.length == 0) return
      this.$vuetify.goTo($node[0])
    },
    checkHasWritePermission(treeConfig) {
      if(!treeConfig) return true
      if(typeof treeConfig.hasWritePermission != 'function') return true
      return treeConfig.hasWritePermission((...args) => window.tokenStore.hasRole(...args))
    },
  },
  computed: {
    treeActions() {
      return this.$store.getters[`tree/${this.treeKey}/actions`]
    },
    treeApi() {
      return this.$store.getters[`tree/${this.treeKey}/api`]
    },
    treeMeta() {
      return this.$store.getters[`tree/${this.treeKey}/meta`]
    },
    treeConfig() {
      return this.$store.getters[`tree/${this.treeKey}/config`]
    },
    treeData() {
      return this.$store.getters[`tree/${this.treeKey}/data`]
    },
  },
}
