<template>
  <eagle-tree
    :tree-key="treeKey"
  ></eagle-tree>
</template>

<script lang="babel" type="text/babel">
import treeMixin from '@/components/tree/mixins/treeMixin'
import treeConfig from './pageCategoryTreeConfig'
export default {
  mixins: [treeMixin],
  data: () => ({
    treeKey: 'page-category',
    meta: {},
  }),
  methods: {
    async getTreeApi() {
      return await this.$api.collection.pageCategoryApi.tree(this.pageType)
    },
    async createApi(id, data) {
      return await this.$api.collection.pageCategoryApi.create(this.pageType, id, data)
    },
    async updateApi(id, data) {
      return await this.$api.collection.pageCategoryApi.update(id, data)
    },
    async moveApi(from ,to, mode) {
      return await this.$api.collection.pageCategoryApi.move(from, to, mode)
    },
    async deleteApi(id) {
      return await this.$api.collection.pageCategoryApi.delete(id)
    },
    getTreeConfig() {
      return treeConfig
    },
  },
  computed: {
    pageType() {
      return this.$route.meta.type
    },
    feature() {
      return this.$store.getters['base/feature']
    },
    maxDepth() {
      return window.eagleLodash.get(this.feature, `pageTypes.${this.pageType}.depth`) || 1
    },
  },
  watch: {
    pageType() {
      this._initTree()
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
