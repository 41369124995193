import Vue from 'vue'
import { EagleTreeConfigInterface } from '@/components/tree/types/tree'
class treeConfig {
  protected vm?: Vue
  protected treeKey?: string
  protected pageType?: string

  get(vm: Vue, treeKey: string): EagleTreeConfigInterface {
    this.vm = vm
    this.treeKey = treeKey
    this.pageType = this.vm.$route.meta.type

    return {
      pageTitle: this.vm.$t(`page.type.${this.pageType}`),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PAGE']),
      label: node => node.name,
      // @ts-ignore
      max: this.vm.maxDepth,
    }
  }
}

export default new treeConfig()
